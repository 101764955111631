<template>
<div class="container new-container news-list">
    <el-empty v-if="!topNewsList || topNewsList.length == 0" :image-size="200"></el-empty>
    <el-row v-else>
        <el-col :span="16" style="position: relative; cursor: pointer;">
            <el-image
                v-if="checkFile(currentNew.coverUrl, 'image')"
                style="width: 100%; height: 392px;"
                :src="currentNew.coverUrl"
                fit="cover"
                @click="$router.push('/newsDetail?id='+currentNew.id)">
             </el-image>

            <video-player
                v-else
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                @play="onPlayerPlay($event)"
                @fullscreenchange="onPlayerFullScreenchange($event)"
                @click="fullScreen"
                :options="playerOptions"
          ></video-player>

          <div class="n-ellipsis cn-title" @click="$router.push('/newsDetail?id='+currentNew.id)">
              <template v-if="currentNew.content">{{htmlFormatter(currentNew.content)}}</template>
              <template v-else>{{currentNew.title}}</template>
        </div>
        </el-col>
        <el-col :span="8">
            <div class="news-carousel" id="news-carousel">
                <!-- <div class="arr-bg prev" @click="prev">
                    <i class="el-icon-arrow-up" color="gray"></i>
                </div> -->
                <ul id="news-slider1">
                    <li v-for="item in topNewsList" :key="item.id" @click="newsDetail($event, item)">
                        <p class="n-more-line-ellipsis news-title">{{item.title}}</p>
                        <div class="news-time">{{item.createTime}}</div>
                    </li>
                </ul>
                <!-- <ul id="news-slider2"></ul>
                <div class="arr-bg next" @click="next">
                    <i class="el-icon-arrow-down"></i>
                </div> -->
            </div>
            <!-- <el-carousel class="news-carousel" arrow="always" height="200px" direction="vertical" :autoplay="false">
                <el-carousel-item v-for="item in newsList" :key="item.id">
                    <p class="more-line-ellipsis news-title">{{item.title}}</p>
                    <div class="news-time">{{item.createtime}}</div>
                </el-carousel-item>
            </el-carousel> -->
        </el-col>
    </el-row>
    <div class="news-t">
        <span>新闻动态</span>
        <el-input
            class="news-search"
            placeholder="请输入搜索内容"
            size="small"
            v-model="queryParam.title"
            @change="search"
            @blur="search">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
    </div>
    <div style="display: flex; flex-direction: row;">
        <div class="nc">
            <div class="news-categorys">
                <!-- <div style="width: 4px; height: 100%; background-color: #F5F5F5"></div> -->
                <div class="news-category" @click="changeCategory(item.id)" :class="currentCategory==item.id?'active':''" v-for="(item,index) in categoryList" :key="index">
                    <div class="nc-title">{{item.name}}</div>
                    <div class="nc-point">
                        <div class="nc-round">
                            <img :src="roundImg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="flex: 1; margin: 20px 0px;">
            <el-empty v-if="!newsList || newsList.length == 0" :image-size="200"></el-empty>
            <div v-else>
                <div class="news-list">
                    <div class="news-item" v-for="item in newsList" :key="item.id" @click="$router.push('/newsDetail?id='+item.id)">
                        <el-image
                        style="width: 232px; height: 131px; cursor: pointer;"
                        :src="item.coverUrl?item.coverUrl:defaultImg"
                        fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                            </div> 
                        </el-image>
                        <div class="news-detail">
                            <div class="n-ellipsis nd-title">{{item.title}}</div>
                            <div class="n-more-line-ellipsis3 nd-cnt">{{htmlFormatter(item.content)}}</div>
                            <div class="news-time">{{item.createTime}}</div>
                        </div>
                    </div>
                </div>
                <div class="content-pagination">
                    <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="queryParam.pageNo"
                    :page-size="queryParam.pageSize"
                    layout="prev, pager, next,jumper"
                    :total="queryParam.total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</div>  
</template>
<script>
import { getTopNews, getNewsByCategory, getCategorys, getNewsByTitle } from '@/api/common';
import {htmlFormatter} from '@/utils/index'
import Vue from 'vue';
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import hls from "videojs-contrib-hls";
Vue.use(hls);
Vue.use(VideoPlayer)
import $ from 'jquery'

export default {
    name:"news",
    data(){
        return{
            defaultImg: require('@/assets/image/news.jpg'),
            roundImg: require('@/assets/image/round.png'),
            topNewsList:[],
            currentNew: {},
            newsList:[],
            categoryList:[],
            currentCategory: null,
            keyword: '',
            queryParam:{
                catId:0,
                title: '',
                pageSize:10,
                pageNo:1,
                total: 0
            },
            playerOptions: {
                //height: '100%',
                width: '100%',
                autoplay: true,
                aspectRatio: '16:9',
                muted: true,
                language: 'zh-CN',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [],
                notSupportedMessage: '此视频暂时无法播放，请稍后再试',
                poster: '',
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                },
            },  
            timer: null,
            timeout: null,
        }
    },
    watch:{
        currentCategory:{
            handler(val){
                if(!val) return
                this.queryParam = {
                    catId: val,
                    title: '',
                    pageSize:10,
                    pageNo:1,
                }
                this.initNews()
            },
            immediate: true
        }
    },
    created(){
        this.initData()
    },
    methods:{
        newsDetail(event, news){
            this.currentNew = news
            //if(event.target.tagName == "P") this.$router.push('/newsDetail?id='+news.id)
        },
        //去掉html标签
        htmlFormatter(html){
            return htmlFormatter(html);
        },
        initData(){
            // this.newsList = [{id:1, title: '测试新闻标题', createtime: '2019/10/11',content:"这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容", imgUrl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fphoto%2F2012-3-26%2Fenterdesk.com-3947C8124CD406DC594BEDF507AB07DC.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1645084022&t=b5f1ce47865d04e4893dd0d40ac02d54', videoUrl:'https://eqibian-course-video.oss-cn-shenzhen.aliyuncs.com/mofaxiao/mofaxiao202109autumncourse15s/course13/spacecity.mp4?versionId=CAEQShiBgMD995jW4BciIGU3ZjU1N2U1YzdhOTQ1OTNiN2Y2ZDFiM2I1YjZiNzYy'},
            // {id:2, title: '测试2新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题abc',content:"这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容", createtime: '2019/10/11', imgUrl:'', videoUrl:'https://eqibian-course-video.oss-cn-shenzhen.aliyuncs.com/mofaxiao/mofaxiao202109autumncourse15s/course13/spacecity.mp4?versionId=CAEQShiBgMD995jW4BciIGU3ZjU1N2U1YzdhOTQ1OTNiN2Y2ZDFiM2I1YjZiNzYy'},
            // {id:3, title: '测试3新闻标题新闻标题新',content:"这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容这里是正文内容", createtime: '2019/10/11', imgUrl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.jj20.com%2Fup%2Fallimg%2F911%2F050916125K7%2F160509125K7-11.jpg&refer=http%3A%2F%2Fpic.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1645168041&t=e357046cd9d6af008df0464128a19096', videoUrl:''}]
            // this.currentNew = this.newsList[0]
            getTopNews().then(res => {
                if(res.success){
                    this.topNewsList = res.result.records
                    if(this.topNewsList && this.topNewsList.length > 0) this.currentNew = this.topNewsList[0]
                }
            })
           
            getCategorys().then(res => {
                if(res.success){
                    this.categoryList = res.result.records 
                    if(this.categoryList && this.categoryList.length > 0){
                        this.currentCategory = this.categoryList[0].id
                    }
                }
            })

        },
        initNews(){
             getNewsByCategory(this.queryParam).then(res => {
                if(res.success){
                    let r = res.result
                    this.newsList = r.records
                    this.queryParam.total = r.total
                    this.queryParam.pageNo = r.current
                }
            })
        },
        search(){
            this.currentCategory = null
            this.queryParam.catId = ''
             getNewsByTitle(this.queryParam).then(res => {
                if(res.success){
                    let r = res.result
                    this.newsList = r.records
                    this.queryParam.total = r.total
                }
            })
        },
        handleCurrentChange(){
            if(this.queryParam.title) this.search()
            else this.initNews()
        },
        onPlayerPlay(player) {
        // player.play();
        this.full(player);
      },
      checkFile(fileValue,type) {
            if(!fileValue) return false
            let index = fileValue.indexOf("."); 
            let fileValueSuffix = fileValue.substring(index); 
            if(type == 'video') {
                if (!/(.*)\.(mp4|rmvb|avi|ts)$/.test(fileValueSuffix)) { 
                    return false;
                }
            }
            if(type == 'image') {
                if (!/(.*)\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileValueSuffix)) { 
                    return false;
                }
            }
        
            return true;
        },
      //视频的播放、
      fullScreen() {
        const player = this.$refs.videoPlayer.player;
        player.requestFullscreen(); //调用全屏api方法
        player.isFullscreen(true);
        player.play();
      },
      full(element) {
        //做兼容处理
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.oRequestFullscreen) {
          element.oRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullScreen();
        } else {
          // var docHtml = document.documentElement;
          // var docBody = document.body;
          var videobox = document.getElementsByClassName('video-player');
          var cssText = 'width:100%;height:100%;overflow:hidden;';
          // docHtml.style.cssText = cssText;
          // docBody.style.cssText = cssText;
          videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;';
          // document.IsFullScreen = true;
          videobox.IsFullScreen = true;
        }
      },
      changeCategory(cId){
          this.currentCategory = cId
          
      },
      prev(){
        //if(this.topNewsList.length < 4) return
        let lineH= $('#news-slider1').find("li:first").height()
        $('#news-slider1').animate({
            marginTop: -lineH
        },500,function(){
            for(let i=1;i<=1;i++){
                    $('#news-slider1').find("li:first").appendTo($('#news-slider1'));
            }
            $('#news-slider1').css({marginTop:0});
        });

      },
      next(){
        //if(this.topNewsList.length < 4) return
        let lineH= $('#news-slider1').find("li:last").height()
        $('#news-slider1').animate({
            marginTop: lineH
        },500,function(){
            for(let i=1;i<=1;i++){
                $('#news-slider1').find("li:last").prependTo($('#news-slider1'));
            }
            $('#news-slider1').css({marginTop:0});
        });
      },
      scroll(isAdd){
        let _this = this
        let app = $('#news-carousel');
        let slide1 = $('#news-slider1');
        let slide2 = $('news-slider2');
      }
      
    }
    
}
</script>
<style lang="scss" scoped>
.new-container{
    width: 1000px;
    margin: 0px auto;
}
.news-list{
    margin: 5px auto;
    min-height: 460px;
}
.news-list .news-title{
    color: #1f1f1f;
    font-size: 14px;
    height: 43px;
    margin-bottom: 6px;
}
.news-list .news-time{
    color: #1f1f1f;
    font-size: 10px;
    text-align: right;
}
.news-carousel{
    height: 392px;
    position: relative;
    //overflow: hidden; 
    overflow: scroll;

    // &::-webkit-scrollbar {
    //     width: 6px;
    //     height: 6px;
    // }
    // &::-webkit-scrollbar-thumb {
    //     background-color: rgba(0,0,0,.3);
    //     border-radius: 3px;
    // }

    ul{
        //padding-top: 16px;

        li{
            &:last-child{
                border-bottom: 0px;
            }
        }
    }
}
.arr-bg{
    background-color: #e5e5e5;
    height: 16px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    cursor: pointer;
}
.next{
    top: 376px;
}
.news-carousel li{
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #d1d1d1;
}
.news-item{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .news-detail{
        flex: 1;
        margin-left: 15px;
        width: 553px;

        .nd-title{
            font-size: 20px;
            font-weight: 400;
            cursor: pointer;
        }
        .nd-cnt{
            height: 72px;
            font-size: 14px;
        }
        .news-time{
            color: #808080;
            position: relative;
            top: 5px;
        }
    }
}
.news-t{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    
    span{
        font-size: 20px;
        font-weight: 400;

        &::before{
            content: " ";
            display: inline-block;
            width: 4px;
            height:19px;
            background-color: #CB0010;
            margin-right: 8px;
            position: relative;
            top: 2px;
        }
    }
}
.news-categorys{
    margin-top: 20px;
}
.news-categorys::before, .news-categorys::after{
    content: " ";
    display: inline-block;
    width: 4px;
    height:25px;
    background-color: #F5F5F5;
    position: relative;
    left: 146px;
}
.news-categorys::after{
    height: 0px;
}

.news-category{
    display: flex;
    flex-direction: row;
    height: 80px;
    cursor: pointer;
    //align-items: center;
    
    .nc-title{
        width: 129px;
        height: 36px;
        line-height: 36px;
        color: #888888;
        font-size: 10px;
        padding: 0px 10px;
        background-color:#F5F5F5;
        border-radius: 36px;
        position: relative;
        top: -18px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        transition: all linear .25s;

        &:hover{opacity: .8; background: #5F80FF; color: #ffffff;}
        &:active{opacity: .9;}
    }
    .nc-point{
        position: relative;
        width: 50px;
        border-left: 4px solid #f5f5f5;
        border-top: 4px solid #f5f5f5;
        margin-left: 16px;

        .nc-round{
            position: absolute;
            top: -10px;
            left: -9px;
            background: #ffffff;
            width: 24px;
            height:14px;
            line-height: 11px;
            //background-color: green;
        }

        img{
            width: 14px;
            height: 14px;
        }
    }
}
.news-category.active .nc-title{
    background-color: #5F80FF;
    color: #ffffff;
}
.cn-title{
    position: absolute; 
    top: 340px; 
    left: 0px; 
    width: 100%; 
    height: 52px;
    padding: 15px;
    background: rgba(0,0,0,.3); 
    color: #ffffff;
    cursor: pointer;
    transition: all linear .25s;
    

    &:hover{
        background: rgba(0,0,0,.5); 
    }
}
.nc{
    width: 200px;
}
.news-search{
    width: 300px; 
}
.content-pagination{
    text-align: right;
}

.scroll-up{
    animation: moveUp 1s linear;
}

.scroll-down{
    animation: moveDown 1s linear;
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-60px);
    }
}
@keyframes moveDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-60px);
    }
}
</style>
<style>
    .news-search .el-input__inner{
        border-radius: 30px
    }
    .n-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .n-more-line-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        /*！autoprefixer: off */  
            -webkit-box-orient: vertical;  
            -webkit-line-clamp: 2;
        /*！autoprefixer: on */ 
    }
    .n-more-line-ellipsis3 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        /*！autoprefixer: off */  
            -webkit-box-orient: vertical;  
            -webkit-line-clamp: 3;
        /*！autoprefixer: on */ 
    }
</style>